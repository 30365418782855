import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { MarkGithubIcon} from '@primer/octicons-react';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href="https://github.com/mmahir1" aria-label='Github' target="_blank" rel="noreferrer"> <MarkGithubIcon /></a>
        </div>
        <div>
          <a href='https://twitter.com/mahir_musayyib' aria-label='Twitter' target="_blank" rel="noreferrer"><BsTwitter /></a>
        </div>
        <div>
            <a href='https://www.instagram.com/mahir_musayyib/' aria-label="Instagram" target="_blank" rel="noreferrer"><BsInstagram /></a>
        </div>
    </div>
  )
}

export default SocialMedia;