import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  

  return (
    <>
      <h2 className="head-text">Get In Touch with Me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:ymusayyib@gmail.com" className="p-text" target='blank' rel="noreferrer">ymusayyib@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+2348133946022" className="p-text" >+234 813 394 6022</a>
        </div>
        <div className="app__footer-card">
          <img src={images.linkedin} alt="linkedin" />
          <a href="https://www.linkedin.com/in/musayyib-yusuf-9b9b53216" className="p-text" target="_blank" rel="noreferrer">Musayyib Yusuf</a>
        </div>
      </div>

    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);